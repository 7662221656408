import React from 'react';
import DownArrowIcon from './down-arrow.svg';
import { useTheme } from '../contexts/ThemeContext';
import { themeColors } from '../theme/colors';

function LoadMoreFilingCards({ title = {}, numItems = 10, onLoadMore }) {
    const { isDarkMode } = useTheme();
    
    const cardClasses = `${isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-100 hover:bg-gray-200'} px-4 py-1 cursor-pointer transition-colors duration-150`;

    return (
        <div className={cardClasses} onClick={onLoadMore}>
            <div className={`text-sm ${isDarkMode ? 'hover:text-blue-400' : 'hover:text-blue-500'} ${themeColors.text.primary}`}>
                <div className="flex items-center m-1">
                    <div className="flex items-center justify-center mx-auto">
                        <div className="flex items-center justify-center mx-auto">
                            <img 
                                src={DownArrowIcon} 
                                alt="Load More" 
                                className={`w-2 h-2 ${isDarkMode ? 'filter invert' : ''}`} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadMoreFilingCards;
