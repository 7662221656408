import React, { useState, useRef, useEffect } from 'react';
import useSSE from '../SSE/sse';
import {API_URL} from "../../config";
import LoginRequiredModal from "../YouMustBeLoggedIn/YouMustBeLoggedIn";

const SummarizeIcon = () => (
    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 2V8H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="currentColor"/>
        <path d="M12 14V13.5C12.5304 13.5 13.0391 13.2893 13.4142 12.9142C13.7893 12.5391 14 12.0304 14 11.5C14 10.9696 13.7893 10.4609 13.4142 10.0858C13.0391 9.71071 12.5304 9.5 12 9.5C11.4696 9.5 10.9609 9.71071 10.5858 10.0858C10.2107 10.4609 10 10.9696 10 11.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const SummarizeButton = ({ fid, currentUser }) => {
    const [isStreaming, setIsStreaming] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const { data, error } = useSSE(streamUrl);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const modalRef = useRef(null);
    const summaryRef = useRef(null);
    //
    const [displayData, setDisplayData] = useState('');

    const handleSummarize = () => {
        if (!currentUser?.token) {
            setIsLoginModalOpen(true);
            return;
        }

        console.log("user logged in, token: " + currentUser.token)

        setIsStreaming(true);
        setStreamUrl(`${API_URL}/api/company/insider-trades/individual-trades/summarize/streamed/${fid}`);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsStreaming(false);
        setStreamUrl('');
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {

        if (summaryRef.current) {
            summaryRef.current.scrollTop = summaryRef.current.scrollHeight;
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            // TODO So this works... until the very end. Could it be that the last bit of the data transmissions is problematic for this code?
            setDisplayData(data);
        }
    }, [data]);

    return (
        <div className="relative">
            <button
                onClick={handleSummarize}
                disabled={isStreaming}
                className={`flex items-center justify-center p-1 text-white rounded-md transition-colors duration-300 ${isStreaming ? 'bg-gray-400 dark:bg-gray-600' : 'bg-gray-400 dark:bg-gray-600 hover:bg-green-600 dark:hover:bg-green-500'}`}
                title="Summarize"
            >
                {/* TODO make it so that these icons don't cover the modal - somehow that is happening*/}
                <SummarizeIcon />
            </button>
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div ref={modalRef} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[80vh] flex flex-col">
                        <button
                            className="self-end text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                        <h4 className="text-lg font-bold mb-4 text-gray-900 dark:text-gray-100">Summary of transactions:</h4>
                        <div
                            ref={summaryRef}
                            className="flex-grow overflow-y-auto bg-gray-100 dark:bg-gray-700 p-4 rounded-md"
                        >
                            <div
                                className="text-gray-900 dark:text-gray-100 text-md text-left font-semibold leading-relaxed"
                                dangerouslySetInnerHTML={{ __html: displayData || "Generating summary..." }}
                            />
                        </div>
                        {error && (
                            <p className="text-red-500 mt-2">
                                Error: {typeof error === 'object' ? error.message || JSON.stringify(error) : error}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <LoginRequiredModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
        </div>
    );
};

export default SummarizeButton;