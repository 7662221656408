import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { themeColors } from '../theme/colors';

function CompanyColumn({ companies }) {
    useTheme(); // Keep theme context subscription active
    return (
        <div className={`shadow rounded-sm ${themeColors.border.default} bg-gray-50 dark:bg-gray-900`}>
            {companies.map((company) => (
                <Link key={company.ticker ? company.ticker[0] : company.cik} to={`/company/${company.ticker ? company.ticker[0] : company.cik}`} className="rounded-sm w-full flex">
                    <div className={`bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 border-t ${themeColors.border.default} md:py-1 py-2 m-0 w-full transition-colors duration-150`}>
                        <div className="flex flex-row justify-between items-center w-full px-2 rounded-sm">
                            <div className="flex items-center gap-2 text-xs pl-2 pr-3 w-full">
                                <div className="font-roboto flex flex-col items-start justify-start w-full mr-2 whitespace-nowrap">
                                    <h3 className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${themeColors.text.primary}`}>
                                        {company.name} {company.ticker ? '(' + company.ticker[0] + ')' : ''}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default CompanyColumn;
