
const UserSubscriptionDetailsSmall = ({ subscriptionData }) => {



    return (
        (subscriptionData ?

                <div className="bg-white dark:bg-gray-800 px-8 py-4 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm mt-6">
                    <h3 className="text-md text-gray-800 dark:text-gray-100 mb-4">Current Subscription Details</h3>

                    {subscriptionData.map((subscription, index) => (
                        <div key={index} className="mb-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Product</p>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-gray-100">{subscription.product_name}</p>
                                </div>
                                <div>
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Amount</p>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-gray-100">{`$${subscription.amount / 100}`}</p>
                                </div>
                                <div>
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Current Period</p>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-gray-100">
                                        {`${new Date(subscription.current_period_start).toLocaleDateString()} - ${new Date(subscription.current_period_end).toLocaleDateString()}`}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Auto-renew</p>
                                    <p className="mt-1 text-sm text-gray-900 dark:text-gray-100">{subscription.cancel_at_period_end ? 'No' : 'Yes'}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                :
                <></>
        )

    );
};

export default UserSubscriptionDetailsSmall;