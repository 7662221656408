import React, { useState, useEffect } from "react";
import { formatCurrency, formatCurrencyCompact } from "../../../values";

export function InsiderInfoCell({ ownerCIK, ownerName, ownerRelationship }) {
    const [relationshipText, setRelationshipText] = useState(ownerRelationship);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 640) {
                const maxLength = Math.max(13, Math.floor(screenWidth / 40));
                if (ownerRelationship === "10% Owner") {
                    setRelationshipText("10%")
                } else {
                    setRelationshipText(ownerRelationship.slice(0, maxLength) + (ownerRelationship.length > maxLength ? '...' : ''));
                }

            } else {
                setRelationshipText(ownerRelationship);
            }
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [ownerRelationship]);

    return (
        <td className="max-w-fit overflow-hidden text-ellipsis whitespace-nowrap dark:text-gray-200">
            <a href={`/company/insider/${ownerCIK}`} title={`${ownerName} - ${ownerRelationship}`}>
                {ownerName} <span className="text-gray-500 dark:text-gray-400 ml-1 sm:ml-2">{relationshipText}</span>
            </a>
        </td>
    );
}

export function InsiderDateCell({ date }) {
    const d = new Date(date);
    const shortFormat = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().slice(-2)}`;
    const longFormat = d.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    return (
        <td className="px-1 pl-2 py-1 dark:text-gray-200">
            <span className="sm:hidden">{shortFormat}</span>
            <span className="hidden sm:inline">{longFormat}</span>
        </td>
    );
}



export function ValueCell({value}) {
    const fullFormat = typeof value === 'number' ? formatCurrency(value, 0, "$") : '';
    const compactFormat = typeof value === 'number' ? formatCurrencyCompact(value) : '';

    return (
        <td className="text-right dark:text-gray-200">
            <span className="hidden sm:inline" title={fullFormat}>{fullFormat}</span>
            <span className="sm:hidden" title={fullFormat}>{compactFormat}</span>
        </td>
    );
}