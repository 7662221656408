// CompanyV3.js

import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import FooterSmall from "../FooterSmall";
import { useAuth } from "../../firebase/AuthContext";
import NavBarSmallV2 from "../NavBarSmallV2";
import { API_URL } from '../../config.js';
import FilingCategory from "../FilingCatagory";
import FilingCategoryOwnershipFilings from "../FilingCatagoryOwnership";
import ModeledgeName from "../Modeledge";
import SimilarCompaniesV2 from "../SimilarCompaniesV2";
import DocumentSearchBarV3 from "../DocumentSearchV3";
import CompanyDescription from "../LLMQuery/CompanyDescription";
import Transcripts from "../Transcript/TranscriptPageV2";
import TransactionsTableTGGroup from "../InsiderTransactions/InsidersTGGroup";
import { arrayToStringWithCommasAndLengthLimitIntelligent } from "../../values";
import TableSearchWithModalV2 from "../TableSearchInput/TableSearchWithModalV2";
import { CompanyQueryInputV1 } from "../LLMQuery/CompanyQueryV2";
// CompanyEnhanced.js


function CompanyEnhanced() {
    const [activeTab, setActiveTab] = useState('Filings');
    const [companyData, setCompanyData] = useState(null);
    const [similarCompanies, setSimilarCompanies] = useState(null);
    const [filingsData, setFilingsData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const { ticker } = useParams();
    const { currentUser, refreshToken } = useAuth();
    const navigate = useNavigate();

    const fetchCompanyData = useCallback(async () => {
        try {
            const headers = currentUser ? { Authorization: `Bearer ${currentUser.token}` } : {};
            const response = await axiosInstance.get(`${API_URL}/api/company/simple/${ticker}`, { headers });
            setIsFollowing(response.data.isFollowing);
            setCompanyData(response.data.company);
            setSimilarCompanies(response.data.similar);
        } catch (error) {
            console.error('Error fetching company data:', error);
        }
    }, [ticker, currentUser]);

    const fetchFilingsData = useCallback(async () => {
        try {
            const headers = currentUser ? { Authorization: `Bearer ${currentUser.token}` } : {};
            const response = await axiosInstance.get(`${API_URL}/api/company/filings/${ticker}`, { headers });
            setFilingsData(response.data.filings);
        } catch (error) {
            console.error('Error fetching filings data:', error);
        }
    }, [ticker, currentUser]);

    useEffect(() => {
        fetchCompanyData();
        fetchFilingsData();
    }, [fetchCompanyData, fetchFilingsData]);

    const handleFollowToggle = async () => {
        if (!currentUser) return navigate('/login');
        try {
            const headers = { Authorization: `Bearer ${currentUser.token}` };
            const url = `${API_URL}/api/users/portfolio/${isFollowing ? 'remove' : 'add'}/main/${ticker}`;
            const response = await axiosInstance.post(url, {}, { headers });
            setIsFollowing(response.data.isFollowing);
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    };

    const categories = [
        { key: 'News', component: FilingCategory },
        { key: 'Financial', component: FilingCategory },
        { key: 'Prospectus', component: FilingCategory },
        { key: 'Proxies', component: FilingCategory },
        { key: 'Ownership', component: FilingCategoryOwnershipFilings },
        { key: 'SEC Correspondence', component: FilingCategory },
    ];

    const renderFilingCategories = () => {
        if (filingsData && typeof filingsData === 'object' && Object.keys(filingsData).length > 0) {
            return categories.map(({ key, component: Component }) => (
                <Component
                    key={`${key}_${ticker}_${filingsData[key]?.length}`}
                    title={key}
                    filings={filingsData[key] || []}
                    ticker={ticker}
                    currentUser={currentUser}
                />
            ));
        }

        return categories.map(({ key }) => (
            <div key={key} className="bg-white p-4 rounded shadow">
                <h2 className="text-lg font-bold mb-2">{key}</h2>
            </div>
        ));
    };

    const tabs = ['Filings', 'Transcripts', 'Insider Transactions', 'LLM Query'];

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Filings':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {renderFilingCategories()}
                    </div>
                );
            case 'Transcripts':
                return (
                    <div>
                        <Transcripts ticker={ticker} currentUser={currentUser} />
                    </div>
                );
            case 'Insider Transactions':
                return (
                    <div>
                        <h2 className="text-xl font-semibold mb-4">Insider Transactions</h2>
                        <TransactionsTableTGGroup ticker={ticker} currentUser={currentUser} />
                    </div>
                );
            case 'LLM Query':
                return (
                    <div>
                        <h2 className="text-xl font-semibold mb-4">Query Company Data with AI</h2>
                        <CompanyQueryInputV1
                            companyID={companyData.cik}
                            currentUser={currentUser}
                            refreshToken={refreshToken}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    if (!companyData) {
        return (
            <div className="flex min-h-screen bg-gray-100">
                <NavBarSmallV2 />
                <div className="flex-1 flex items-center justify-center">
                    <p className="text-gray-600 text-lg">Loading company data...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex min-h-screen bg-gray-100">
            {/* Sidebar */}
            <aside className="hidden lg:flex lg:flex-col w-64 bg-white border-r">
                <div className="flex items-center h-16 px-6 bg-gray-50 border-b">
                    <a href="/portfolio" className="flex items-center space-x-2">
                        <img src="/images/logo.png" alt="Logo" className="w-8 h-8" />
                        <ModeledgeName />
                    </a>
                </div>
                <nav className="flex-1 px-6 py-4 space-y-6">
                    <div>
                        <h3 className="text-sm font-semibold text-gray-700 mb-2">Search Tables</h3>
                        <TableSearchWithModalV2 ticker={ticker} />
                    </div>
                    <div>
                        <h3 className="text-sm font-semibold text-gray-700 mb-2">Search Documents</h3>
                        <DocumentSearchBarV3 ticker={ticker} />
                    </div>
                    <div>
                        <button
                            className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500"
                            onClick={() => navigate(`/company/insider/${ticker}`)}
                        >
                            Insider Transactions
                        </button>
                    </div>
                    <div>
                        <h3 className="text-sm font-semibold text-gray-700 mb-2">Similar Companies</h3>
                        <SimilarCompaniesV2 ticker={ticker} similar={similarCompanies} />
                    </div>
                </nav>
            </aside>

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                <NavBarSmallV2 />
                <main className="flex-1 overflow-y-auto">
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        {/* Company Header */}
                        <div className="bg-white shadow rounded-lg p-6 mb-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900">
                                        {companyData.name}
                                        {companyData.tickers && (
                                            <span className="text-gray-500 ml-2 text-lg">
                                                ({arrayToStringWithCommasAndLengthLimitIntelligent(companyData.tickers)})
                                            </span>
                                        )}
                                    </h1>
                                    <p className="text-sm text-gray-600">
                                        {companyData.businessCity}, {companyData.businessState}
                                    </p>
                                </div>
                                <button
                                    className={`px-4 py-2 rounded-md text-sm font-medium ${
                                        isFollowing
                                            ? 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                            : 'bg-blue-600 text-white hover:bg-blue-500'
                                    }`}
                                    onClick={handleFollowToggle}
                                >
                                    {isFollowing ? 'Unfollow' : 'Follow'}
                                </button>
                            </div>
                            <div className="mt-4">
                                <CompanyDescription ticker={ticker} />
                            </div>
                        </div>

                        {/* Tabs */}
                        <div className="bg-white shadow rounded-lg">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8 px-6">
                                    {tabs.map((tab) => (
                                        <button
                                            key={tab}
                                            onClick={() => setActiveTab(tab)}
                                            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                                                activeTab === tab
                                                    ? 'border-blue-600 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                            }`}
                                        >
                                            {tab}
                                        </button>
                                    ))}
                                </nav>
                            </div>
                            <div className="p-6">{renderTabContent()}</div>
                        </div>
                    </div>
                </main>
                <FooterSmall />
            </div>
        </div>
    );
}

export default CompanyEnhanced;
