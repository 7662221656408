import React from "react";
import { Link } from "react-router-dom";
import Arrow from "./Arrow/Arrow2";
import FilingMenuExhibitList from "../Components/FilingMenuExhibitList";
import FilingMenuSecondaryData from "../Components/FilingMenuSecondaryData";
import CompanyButton from "./CompanyButton/CompanyButton";
import Logo from "../Components/Logo";

const SECFilingMenu = ({
                           ticker,
                           type,
                           filingData,
                           menuVisible,
                           toggleMenu,
                       }) => {

    if (!filingData || !filingData.company || !filingData.sec_filing || !filingData.exhibits) {
        return <div>Loading data, please wait...</div>;
    }

    const { company, sec_filing, exhibits } = filingData;

    return (
        <div
            className={`relative transition-transform duration-100 z-5 overflow-y-auto h-screen ${menuVisible ? '' : 'translate-x-[-100%+25px]'}`}
        >
            <Arrow onClick={toggleMenu} />
            <div className="font-sans bg-gray-100 dark:bg-gray-800 overflow-y-auto h-full w-80">
                <div className="flex justify-center no-underline text-white">
                    <h1 className="font-nunito text-gray-900 dark:text-gray-100 mr-6 ml-2 my-4 mb-5 pt-1 text-3xl font-semibold font-sans">
                        <Logo />
                    </h1>
                </div>
                <div className={"rounded-sm mx-3 mb-2"}>
                <CompanyButton company={company} ticker={ticker} />
                <FilingMenuSecondaryData sec_filing={sec_filing} />
                </div>
                <div className="px-2 py-1 mx-1">
                    <h3 className="mb-2 font-bold text-gray-950 dark:text-white">Filing</h3>
                    <div className="bg-gray-600 rounded-sm hover:bg-gray-700 text-sm px-2 py-1 mb-2 border border-gray-600">
                        <Link to={`/company/${ticker}/filing/${type}/${sec_filing.AccessionNumber}/exhibit/1`} className="text-white">
                            <div className={"flex flex-row"}>
                            <div className="text-white bold">{sec_filing.FilingType} </div><div className="ml-2 text-gray-200 italic">{sec_filing.Title}</div>
                            </div>
                            </Link>
                    </div>
                </div>
                <div className="pb-2 pt-0 px-2 mx-1">
                    <h3 className="mb-2 font-bold text-gray-950 dark:text-white">Exhibits</h3>
                    <FilingMenuExhibitList
                        exhibits={exhibits}
                        ticker={ticker}
                        type={type}
                        sec_filing={sec_filing}
                    />
                </div>
            </div>
        </div>
    );
};

export default SECFilingMenu;