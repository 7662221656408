import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import useSSE from '../SSE/sse';
import { API_URL } from "../../config";
import LoginRequiredModal from "../YouMustBeLoggedIn/YouMustBeLoggedIn";
import { useSubscriptionModal } from "../SubscriptionModal/useSubscriptionModal"
import { Button } from "../ui/button";
import { SubscriptionModal } from "../SubscriptionModal/SubscriptionModal";
import { useNavigate } from 'react-router-dom';
import CompanyArtifact from "./CompanyArtifact";
import CompanyArtifactsList from "./CompanyArtifactList";

const SummarizeIcon = () => (
    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 2V8H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="currentColor"/>
        <path d="M12 14V13.5C12.5304 13.5 13.0391 13.2893 13.4142 12.9142C13.7893 12.5391 14 12.0304 14 11.5C14 10.9696 13.7893 10.4609 13.4142 10.0858C13.0391 9.71071 12.5304 9.5 12 9.5C11.4696 9.5 10.9609 9.71071 10.5858 10.0858C10.2107 10.4609 10 10.9696 10 11.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const QueryOptions = ({ setDataSet }) => {
    const { isDarkMode } = useTheme();
    const radioGroupStyle = "grid grid-cols-1 gap-4 mb-6";
    const radioItemStyle = `flex items-center space-x-3 p-4 rounded-lg border transition-colors cursor-pointer ${
        isDarkMode 
            ? 'border-gray-700 hover:bg-gray-800 bg-gray-900/50' 
            : 'border-gray-200 hover:bg-gray-50 bg-white'
    }`;
    const radioLabelStyle = `flex-1 text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-900'}`;
    const options = [
        {
            value: "current_data",
            label: "Default",
            description: "Target data: News filings, transcripts, last annual filing"
        },
        {
            value: "transcripts_full_text",
            label: "Transcripts Full Text (last 3 only)",
            description: "Queries full text of the last 3 transcripts, potentially weak on haystack-like queries"
        }
    ];

    return (
        <div className={radioGroupStyle}>
            {options.map((option) => (
                <label key={option.value} className={radioItemStyle}>
                    <div className="flex items-center space-x-3 flex-1">
                        <input
                            type="radio"
                            id={option.value}
                            name="dataSet"
                            value={option.value}
                            onChange={(e) => setDataSet(e.target.value)}
                            className={`w-4 h-4 ${
                                isDarkMode
                                    ? 'text-blue-500 bg-gray-700 border-gray-600 focus:ring-blue-400 focus:ring-offset-gray-900'
                                    : 'text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-offset-white'
                            } focus:ring-2 focus:ring-offset-2 transition-colors`}
                        />
                        <div className="flex-1">
                            <span className={radioLabelStyle}>{option.label}</span>
                            <p className={`text-sm mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{option.description}</p>
                        </div>
                    </div>
                </label>
            ))}
        </div>
    );
};


const CompanyQueryInputV1 = ({ subscriptionTier, companyID, currentUser, refreshToken }) => {
    const { isDarkMode } = useTheme();
    const [isStreaming, setIsStreaming] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [query, setQuery] = useState('');
    const [queryRequested, setQueryRequested] = useState('');
    const [dataSet, setDataSet] = useState('current_data');
    const { data, error, streamId, isComplete } = useSSE(streamUrl);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [displayData, setDisplayData] = useState('');
    const [localError, setLocalError] = useState(null);
    const { showSubscriptionModal, handleSubscriptionModal, handleSubscriptionModalClose } = useSubscriptionModal();
    const navigate = useNavigate();
    const [showFeedback, setShowFeedback] = useState(false);
    const inputRef = useRef(null);
    const responseRef = useRef(null);
    const [currentArtifactId, setCurrentArtifactId] = useState(null);

    const handleDeleteArtifact = (deletedStreamId) => {
        console.log('Artifact deleted:', deletedStreamId);
        if (currentArtifactId === deletedStreamId) {
            setIsStreaming(false);
            setDisplayData('');
            setShowFeedback(false);
            setQueryRequested('');
            setQuery('');
            setCurrentArtifactId(null);
        }
        // Trigger a refresh of the CompanyArtifactsList
        if (companyArtifactsListRef.current) {
            companyArtifactsListRef.current.refreshArtifacts();
        }
    };

    const handleSummarize = () => {
        if (!currentUser?.token) {
            setIsLoginModalOpen(true);
            return;
        }
        setDisplayData('');
        setLocalError(null);
        setIsStreaming(true);
        setShowFeedback(false);
        setQueryRequested(query);

        let dataId = 'current_data';
        if (dataSet === 'transcript_data') {
            dataId = 'tc';
        } else if (dataSet === 'transcripts_full_text') {
            dataId = 'tft';
        } else if (dataSet === 'current_data') {
            dataId = 'cd';
        } else if (dataSet === 'most_recent_transcript') {
            dataId = 'mrt';
        }

        setStreamUrl(`${API_URL}/api/company/query/llm/${companyID}?q=${query}&auth=${currentUser.token}&d=${dataId}`);
    };

    const companyArtifactsListRef = useRef(null);

    useEffect(() => {
        if (data && streamId) {
            setDisplayData(data);
            setIsStreaming(false);
            setCurrentArtifactId(streamId);
        }
    }, [data, streamId]);

    useEffect(() => {
        if (streamId) {
            console.log("stream_id: " + streamId)
        }
    }, [streamId]);

    useEffect(() => {
        if (isComplete && !isStreaming) {
            setShowFeedback(true)
        }
    }, [isComplete, isStreaming])

    // New effect for scrolling the page to the bottom
    useEffect(() => {
        if (responseRef.current) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [displayData]);

    useEffect(() => {
        if (localError) {
            setIsStreaming(false);
            switch(localError.type) {
                case 'forbidden':
                    handleSubscriptionModal();
                    break;
                case 'unauthorized':
                    setIsLoginModalOpen(true);
                    break;
                case 'connection':
                case 'unknown':
                default:
                    setDisplayData('');
                    break;
            }
        }
    }, [localError, handleSubscriptionModal]);


    useEffect(() => {
        if (error) {
            console.log('Component received error:', error);
            setLocalError(error);
            setIsStreaming(false);  // Enable the button if there's an error

            if (error.message === "Unauthorized") {
                console.log("User: " + currentUser.token);
                refreshToken();
            }
        }
    }, [error, currentUser, refreshToken]);


    const getCurrentFormattedDate = () => {
        const now = new Date();
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const year = now.getFullYear();
        const month = months[now.getMonth()];
        const day = now.getDate();
        const hour = now.getHours();
        const minute = now.getMinutes().toString().padStart(2, '0');
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;

        return `${month} ${day}, ${year}, ${formattedHour}:${minute} ${period}`;
    };

    return (
        <div className="w-full max-w-4xl mx-auto space-y-2">
            <div className={`rounded-lg shadow-lg p-6 ${isDarkMode ? 'bg-gradient-to-b from-gray-900 to-gray-800' : 'bg-gradient-to-b from-gray-100 to-white'}`}>
                <h2 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-gray-100' : 'text-gray-900'}`}>Query Company Data With AI Language Model</h2>
                <div className="space-y-6">
                    <div className={`border rounded-lg p-4 ${isDarkMode ? 'bg-amber-500/10 border-amber-500/20' : 'bg-amber-50 border-amber-200'}`}>
                        <div className="flex items-start space-x-3">
                            <span className="text-amber-500">⚠️</span>
                            <p className={`text-sm ${isDarkMode ? 'text-amber-500' : 'text-amber-700'}`}>
                                While we strive for accuracy, this response is generated by an AI language model and may be inaccurate.
                                Please verify important information. No information should be used exclusively to make investment decisions.
                            </p>
                        </div>
                    </div>

                    <div className={`space-y-4 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        <div className="flex items-start space-x-3">
                            <span className={`${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>⚡</span>
                            <p>Our queries take time to run (15s to 1 minute). This is not a chat interface.</p>
                        </div>
                        <div className="flex items-start space-x-3">
                            <span className={`${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>💡</span>
                            <p>Tips: To include specific tables in the data, use table search key words in brackets, like [revenue], [income statement] or [debt]</p>
                        </div>
                    </div>

                    <div className="pt-2">
                        <QueryOptions setDataSet={setDataSet} />
                    </div>
                </div>
            </div>

            <div className={subscriptionTier === 'free' ? 'opacity-70 pointer-events-none' : ''}>
                <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6">
                    <div className="relative">
                        <textarea
                            ref={inputRef}
                            placeholder="Query recent company documents..."
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value);
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    if (!isStreaming && query.trim()) handleSummarize();
                                }
                            }}
                            className="w-full p-4 min-h-[120px] rounded-lg border border-gray-200 dark:border-gray-800 bg-transparent resize-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                        />
                        <div className="absolute bottom-4 right-4">
                            <Button
                                onClick={handleSummarize}
                                disabled={isStreaming || !query.trim()}
                                className="shadow-lg"
                            >
                                {isStreaming ? (
                                    <div className="flex items-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </div>
                                ) : (
                                    <>
                                        <span className="ml-2">Query</span>
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>

                {(displayData || isStreaming) ? (
                    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg mt-6">
                        <div className="p-6">
                            <CompanyArtifact
                                currentUser={currentUser}
                                queryRequested={queryRequested}
                                streamId={currentArtifactId}
                                responseRef={responseRef}
                                isStreaming={isStreaming}
                                displayData={displayData}
                                showFeedback={showFeedback}
                                createdDate={getCurrentFormattedDate()}
                                onDelete={handleDeleteArtifact}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg mt-2">
                        <div className="p-6">
                            <h3 className="text-lg font-semibold mb-4">Previous Queries</h3>
                            <CompanyArtifactsList
                                ref={companyArtifactsListRef}
                                currentUser={currentUser}
                                companyID={companyID}
                                onDelete={handleDeleteArtifact}
                            />
                        </div>
                    </div>
                )}

                {localError && (
                    <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/50 border border-red-200 dark:border-red-800 text-red-700 dark:text-red-200 rounded-lg">
                        <div className="flex items-center space-x-2">
                            <span>⚠️</span>
                            <div>
                                <p className="font-medium">Error Type: {localError.type}</p>
                                <p className="text-sm mt-1">Error Message: {localError.message}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {subscriptionTier === 'free' && (
                <div className="absolute inset-0 backdrop-blur-sm bg-gray-900/50 flex items-center justify-center z-10">
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl p-6 max-w-md mx-4">
                        <div className="text-center">
                            <div className="w-12 h-12 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center mx-auto mb-4">
                                <SummarizeIcon />
                            </div>
                            <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-2">Premium Feature</h3>
                            <p className="text-gray-600 dark:text-gray-300 mb-6">
                                Upgrade your subscription to access advanced AI-powered company analysis
                            </p>
                            <Button
                                onClick={() => navigate('/subscriptions')}
                                className="w-full"
                            >
                                View Subscription Options
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            <LoginRequiredModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
            <SubscriptionModal
                isOpen={showSubscriptionModal}
                onClose={handleSubscriptionModalClose}
                subscriptionRequired="Basic"
                pageMessage="AI Language Model queries available under our Basic subscription."
            />
        </div>
    );
};

export { CompanyQueryInputV1 };