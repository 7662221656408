import React, { useState } from 'react';
import { Button } from "../ui/button";
import { ThumbsUp, ThumbsDown, Trash, Copy, Check } from 'lucide-react';
import { API_URL } from "../../config";

const FeedbackButtons = ({
                             response,
                             user,
                             onFeedbackGiven,
                             streamId,
                             onDelete
                         }) => {
    const [feedbackGiven, setFeedbackGiven] = useState(false);
    const [copied, setCopied] = useState(false);

    const sendFeedback = async (isPositive) => {
        if (feedbackGiven) return;

        const responseHash = await hashResponse(response);

        try {
            const res = await fetch(`${API_URL}/api/company/query/llm/feedback/${streamId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({
                    id: streamId,
                    responseHash,
                    isPositive,
                }),
            });

            if (res.ok) {
                setFeedbackGiven(true);
                onFeedbackGiven();
            } else {
                console.error('Failed to send feedback');
            }
        } catch (error) {
            console.error('Error sending feedback:', error);
        }
    };

    const hashResponse = async (response) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(response);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    };

    const handleDelete = async () => {
        try {
            const res = await fetch(`${API_URL}/api/company/query/llm/delete/${streamId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({
                    id: streamId,
                    action: 'discard'
                })
            });

            if (res.ok) {
                console.log('Successfully deleted');
                onDelete(streamId)

            } else {
                console.error('Failed to delete');
            }
        } catch (error) {
            console.error('Error deleting:', error);
        }
    };

    const handleCopy = async () => {
        try {
            // Replace all <br> tags with newline characters
            const formattedResponse = response.replace(/<br\s*\/?>/gi, '\n');
            await navigator.clipboard.writeText(formattedResponse);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    };

    return (
        <div className="flex justify-end space-x-2 mt-4 text-xs">
            <Button
                onClick={() => sendFeedback(true)}
                disabled={feedbackGiven}
                variant="outline"
                size="sm"
                className={`flex items-center ${feedbackGiven ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                <ThumbsUp className="h-3 w-3" />
            </Button>
            <Button
                onClick={() => sendFeedback(false)}
                disabled={feedbackGiven}
                variant="outline"
                size="sm"
                className={`flex items-center ${feedbackGiven ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                <ThumbsDown className="h-3 w-3" />
            </Button>
            <Button
                onClick={handleDelete}
                variant="outline"
                size="sm"
                className="flex items-center"
            >
                <Trash className="h-3 w-3" />
            </Button>
            <Button
                onClick={handleCopy}
                variant="outline"
                size="sm"
                className="flex items-center"
            >
                {copied ? <Check className="h-3 w-3" /> : <Copy className="h-3 w-3" />}
                <span className="ml-1">{copied ? 'Copied!' : 'Copy'}</span>
            </Button>
        </div>
    );
};

export default FeedbackButtons;