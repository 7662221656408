import React from "react";


export const AboutCenteredDiv = ({ children, className, ...props }) => {
    return (
        <div
            className={`bg-nav-bar-theme text-neutral-200 p-8 pt-6 rounded shadow border-2 border-teal-500 ${className}`}
            {...props}
        >
            {children}
        </div>
    );
};
