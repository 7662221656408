import React, {useEffect, useState} from "react";
import FilingCard from './FilingCards/FilingCard';
import LoadMoreFilingCards from "./LoadMoreFilingsButton";
import axiosInstance from '../api';
import { API_URL } from "../config";

function FilingCategory({ key, filings, title, ticker, currentUser }) {
    const [allFilings, setAllFilings] = useState(filings);

    const loadMoreFilings = async () => {
        try {
            const newFilings = await fetchMoreFilings();
            setAllFilings([...allFilings, ...newFilings]);
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        // Handle the updated filingsData here
    }, [allFilings]);

    const fetchMoreFilings = async () => {
        try {
            const headers = currentUser
                ? { Authorization: `Bearer ${currentUser.token}` }
                : {};
            const response = await axiosInstance.get(`${API_URL}/api/filing/ticker/${ticker}?category=${title}&count=20&start=${allFilings.length}`, { headers});
            return response.data; // Assuming response.data contains the filings
        } catch (error) {
            console.error('Error fetching company data:', error);
            throw error; // Rethrow the error to be caught in loadMoreFilings
        }
    };

    useEffect(() => {
        // Update the state when the filings prop changes
        setAllFilings(filings);
    }, [filings]); // Add filings to the dependency array

    if (allFilings.length === 0) {
        return <></>
    }

    return (
        <div className="bg-gray-200 dark:bg-gray-800 py-2 px-3 rounded">
            <h2 className="text-lg font-bold mb-2 dark:text-gray-200">{title}</h2>
            {allFilings.map((filing, index) => (
                <FilingCard
                    key={filing.AccessionNumber}
                    filing={filing}
                    isLastItem={index === allFilings.length - 1}
                />
            ))}
            <LoadMoreFilingCards title={title} numItems={allFilings.length} onLoadMore={loadMoreFilings}></LoadMoreFilingCards>
        </div>
    );
}

export default FilingCategory;
