import React, {useEffect, useState} from 'react';
import FilingCardWithCompany from './FilingCards/FilingCardWithCompany';
import SpacerCard from '../Components/SpacerCard';
import LoadMorePortfolioFilingsButton from "./LoadMorePortfolioFilingsButton";
import axiosInstance from '../api';
import {API_URL} from "../config";
import { themeColors } from '../theme/colors';

function PortfolioColumn({ filings = [], portfolioName = "main", currentUser }) {
    const [allFilings, setAllFilings] = useState(filings);

    const today = new Date();
    today.setHours(0, 0, 0, 0); // setting hours, minutes, seconds and milliseconds to 0

    let lastLabel = null;

    const loadMoreFilings = async () => {
        try {
            const newFilings = await fetchMoreFilings();
            setAllFilings([...allFilings, ...newFilings]);
        } catch (error) {
            //
        }
    };

    const fetchMoreFilings = async () => {
        try {
            if (portfolioName === "empty") {
                const res = await axiosInstance.get(`${API_URL}/api/users/portfolio/${portfolioName}?count=40&start=${allFilings.length}`);
                return res.data.filings
            } else {
                const headers = currentUser
                    ? { Authorization: `Bearer ${currentUser.token}` }
                    : {};
                const res = await axiosInstance.get(`${API_URL}/api/users/portfolio/${portfolioName}?count=40&start=${allFilings.length}`, {headers});
                return res.data.filings
            }

        } catch (error) {
            throw error; // Rethrow the error to be caught in loadMoreFilings
        }
    };

    useEffect(() => {
        // Update the state when the filings prop changes
        setAllFilings(filings);
    }, [filings]); // Add filings to the dependency array

    return (
        <div className={`shadow rounded-sm ${themeColors.border.default} ${themeColors.background.card}`}>
            <div className={themeColors.background.card}>
                {allFilings.map((filing, index) => {
                    let filingDate = new Date(filing.updated);
                    filingDate.setHours(0, 0, 0, 0);

                    const diffTime = Math.abs(today - filingDate);
                    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                    let label = '';
                    if (diffDays < 1) {
                        label = "TODAY";
                    } else if (diffDays === 1) {
                        label = "YESTERDAY";
                    } else if (diffDays <= 7) {
                        label = "LAST WEEK";
                    } else if (diffDays <= 30) {
                        label = "LAST MONTH";
                    } else if (diffDays <= 90) {
                        label = "LAST QUARTER";
                    } else if (diffDays <= 365) {
                        label = "LAST YEAR";
                    }

                    let content = [];
                    if (label !== lastLabel) {
                        content.push(<SpacerCard key={`spacer-${filingDate}`} text={label} />);
                        lastLabel = label;
                    }

                    content.push(
                        <FilingCardWithCompany
                            key={filing.accessionNumber}
                            filing={filing}
                            isLastItem={index === allFilings.length - 1}
                        />
                    );

                    return content;
                })}
            <LoadMorePortfolioFilingsButton numItems={allFilings.length} onLoadMore={loadMoreFilings}/>
        </div>
        </div>

    );
}

export default PortfolioColumn;
