import React from "react";
import TableSearchDivV6 from "./TableSearchInput/TableSearchDivV6";

const CompanySearchBars = ({ ticker }) => {
    return (
        <div className="container mx-auto pt-2 px-1 md:px-0 w-full" >
            <div className="flex md:flex-row flex-col justify-start items-center md:space-x-4">
                <div className="flex-1 w-full">
                    <TableSearchDivV6 ticker={ticker} />
                </div>
                {/*<div className="flex-1 w-full">*/}
                {/*    <DocumentSearchBarV2 ticker={ticker} />*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default CompanySearchBars;
