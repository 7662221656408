import { FaTwitter } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-nav-bar-theme dark:bg-gray-900 text-teal-300 py-8 border-t border-gray-200 dark:border-gray-700">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/3">
                        <h3 className="text-lg font-semibold mb-4 dark:text-gray-200">Company</h3>
                        <ul>
                            <li><a href="/" className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 transition-colors">Home</a></li>
                            <li><a href="/about" className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 transition-colors">About</a></li>
                            <li><a href="/contact" className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 transition-colors">Contact</a></li>
                        </ul>
                    </div>

                    <div className="w-full md:w-1/3">
                        <h3 className="text-lg font-semibold mb-4 dark:text-gray-200">Legal</h3>
                        <ul>
                            <li><a href="/terms" className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 transition-colors">Terms</a></li>
                            <li><a href="/privacy" className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 transition-colors">Privacy</a></li>
                        </ul>
                    </div>

                    <div className="w-full md:w-1/3">
                        <h3 className="text-lg font-semibold mb-4 dark:text-gray-200">Follow Us</h3>
                        <ul className="flex text-gray-600 dark:text-gray-400">
                            <a href="https://twitter.com/FwdQuarter"
                               className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 mr-4 transition-colors flex items-center gap-2"
                               target="_blank"
                               rel="noopener noreferrer">
                                News
                                <FaTwitter size={25} />
                            </a>
                        </ul>
                    </div>
                </div>

                <div className="pt-4 mt-8 border-t border-teal-700 dark:border-gray-700">
                    <p className="dark:text-gray-400">&copy; 2024 Modeledge - All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;