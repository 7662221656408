import React from "react";
import { theme } from "../../theme"

function FilingCardCompanySearch({ filing = {}, exhibit = {}, isLastItem = false, numItems = 10 }) {
    const getRelativeDate = (date) => {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
            return "Today";
        } else if (date.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0)) {
            return "Yesterday";
        } else {
            return null;
        }
    };

    const formatFilingDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear().toString().substr(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);

        return `${month}/${day}/${year}`;
    };

    const read = filing.read ?? false; // If filing.read is undefined, 'read' will be false

    let cssClasses = `${isLastItem ? "border-b-0 border-gray-400" : "border-gray-400"} ${read ? "text-gray-500" : ""
    }`;

    console.log("1: " + filing.filing_date)
    console.log("1: " + new Date(filing.filing_date))
    console.log("3: " + formatFilingDate(filing.filing_date))
    const relativeDate = getRelativeDate(new Date(filing.filing_date));
    let dateLabelClass = "text-xs mt-1 py-0.5 px-2 rounded-full";
    if (relativeDate) {
        dateLabelClass += ` ${relativeDate === "Today"
            ? "bg-green-600 text-grey-900"
            : "bg-amber-400 text-grey-900"
        }`;
    }

    return (
        <div className={`${theme.colors.openDataBackground} hover:${theme.colors.openDataBackgroundHover} mb-0 ${cssClasses} py-0 px-1 m-0 border-t`}>
            <div className="flex flex-row justify-between items-center w-full m-1 rounded-sm">
                <div className="flex items-center gap-2 px-1 text-xs justify-between w-full round">
                    <span className="font-roboto font-bold w-14 ml-1 text-gray-200">
                        {filing.filing_type}
                    </span>
                    <span className="text-gray-400">|</span>
                    <div className="font-roboto overflow-ellipsis overflow-hidden whitespace-nowrap w-36 flex-grow text-gray-200" title={filing.title}>
                        {filing.title}
                    </div>
                    <div className="font-roboto flex flex-col items-end justify-end">
                        <span className="text-xs text-gray-200">
                            {formatFilingDate(filing.filing_date)}
                        </span>
                        {relativeDate && (
                            <span className={dateLabelClass}>{relativeDate}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="icon"></div>
        </div>
    );
}

export default FilingCardCompanySearch;
