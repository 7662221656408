import React from "react";
import { formatDateObj, getRelativeDate, parseISODate} from "../../dates";

function FilingCard({ filing = {}, isLastItem = false, numItems = 10 }) {

    const read = filing.read ?? false;
    console.log("1: " + filing.Updated)
    console.log("2: " + parseISODate(filing.Updated))
    console.log("3: " + getRelativeDate(parseISODate(filing.Updated)))
    const relativeDate = getRelativeDate(filing.Updated);

    // Tailwind CSS classes for styling
    let cardClasses = `bg-filing-background dark:bg-gray-700 border shadow-lg ${isLastItem ? 'border-b-2' : ''} border-gray-300 dark:border-gray-600 ${read ? 'text-filing-is-read dark:text-gray-400' : 'text-gray-700 dark:text-gray-200'} hover:bg-gray-200 dark:hover:bg-gray-600 px-4 py-0 transition`;
    let dateLabelClass = "text-vsm mt-1 py-0 px-2 rounded-md font-semibold";
    if (relativeDate) {
        dateLabelClass += ` ${relativeDate === "Today" ? "bg-green-600 hover:text-gray-200 bg-green-500 text-white hover:text-gray-200" : "bg-amber-400 text-gray-900 hover:text-gray-200"}`;
    }

    return (
        <div className={cardClasses}>
            <a href={filing.Url} rel="noopener noreferrer" className="text-sm hover:text-accent-two">
            <div className="flex items-center justify-between m-1 text-xs">
                    <div className="flex items-center gap-1 w-full">
                        <div className="w-16 min-w-16 flex-shrink-0"> <span className="font-semibold w-16 min-w-16">{filing.FilingType}</span></div>
                        <span className="text-gray-300 dark:text-gray-600">|</span>
                        <span className="flex-grow truncate text-ellipsis items-center ml-1 dark:text-gray-300">{filing.Title}</span>
                        <div className="flex flex-col items-end">
                            <span className="text-xs text-gray-500 dark:text-gray-400">{ formatDateObj(parseISODate(filing.Updated))}</span>
                            {relativeDate && <span className={dateLabelClass}>{relativeDate}</span>}
                        </div>
                </div>
            </div>
            </a>
        </div>
    );
}

export default FilingCard;