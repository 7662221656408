import { useState, useEffect, useRef } from 'react';
import { useAuth } from "../firebase/AuthContext";
import { useTheme } from '../contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

const UserMenu = () => {
    const { currentUser, signOutUser } = useAuth();
    const { isDarkMode, toggleDarkMode } = useTheme();
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => setShowMenu(!showMenu);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left rounded-lg" ref={menuRef}>
            {currentUser ? (
                <>
                    <button onClick={toggleMenu}
                            className="px-4 py-1 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-teal-500 transition-colors duration-150">
                        Menu
                    </button>
                    {showMenu && (
                        <div
                            className="absolute right-0 w-56 mt-2 origin-top-right bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 divide-y divide-gray-100 dark:divide-gray-700 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            <div className="px-1 py-1">
                                <div className="flex flex-row">
                                    <a href="/portfolio"
                                       className="text-gray-700 dark:text-gray-300 group flex rounded-sm items-center w-full px-2 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-150">
                                        <div className="p-1">
                                            <svg width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-gray-700 dark:stroke-gray-300"><path d="M3 7.4V3.6C3 3.26863 3.26863 3 3.6 3H9.4C9.73137 3 10 3.26863 10 3.6V7.4C10 7.73137 9.73137 8 9.4 8H3.6C3.26863 8 3 7.73137 3 7.4Z"></path><path d="M14 20.4V16.6C14 16.2686 14.2686 16 14.6 16H20.4C20.7314 16 21 16.2686 21 16.6V20.4C21 20.7314 20.7314 21 20.4 21H14.6C14.2686 21 14 20.7314 14 20.4Z"></path><path d="M14 12.4V3.6C14 3.26863 14.2686 3 14.6 3H20.4C20.7314 3 21 3.26863 21 3.6V12.4C21 12.7314 20.7314 13 20.4 13H14.6C14.2686 13 14 12.7314 14 12.4Z"></path><path d="M3 20.4V11.6C3 11.2686 3.26863 11 3.6 11H9.4C9.73137 11 10 11.2686 10 11.6V20.4C10 20.7314 9.73137 21 9.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z"></path></svg>
                                        </div>
                                        <div className="">
                                            Portfolio
                                        </div>
                                    </a>
                                </div>

                                <div className="flex flex-row">
                                    <a href="/highlights"
                                       className="text-gray-700 dark:text-gray-300 group flex rounded-sm items-center w-full px-2 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-150">
                                        <div className="p-1">
                                            <svg width="18px" height="18px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-gray-700 dark:stroke-gray-300"><path d="M20 12V5.74853C20 5.5894 19.9368 5.43679 19.8243 5.32426L16.6757 2.17574C16.5632 2.06321 16.4106 2 16.2515 2H4.6C4.26863 2 4 2.26863 4 2.6V21.4C4 21.7314 4.26863 22 4.6 22H11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8 10H16M8 6H12M8 14H11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.3056 17.1133L17.2147 15.1856C17.3314 14.9381 17.6686 14.9381 17.7853 15.1856L18.6944 17.1133L20.7275 17.4243C20.9884 17.4642 21.0923 17.7998 20.9035 17.9923L19.4326 19.4917L19.7797 21.61C19.8243 21.882 19.5515 22.0895 19.3181 21.961L17.5 20.9603L15.6819 21.961C15.4485 22.0895 15.1757 21.882 15.2203 21.61L15.5674 19.4917L14.0965 17.9923C13.9077 17.7998 14.0116 17.4642 14.2725 17.4243L16.3056 17.1133Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16 2V5.4C16 5.73137 16.2686 6 16.6 6H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </div>
                                        <div className="">
                                            Highlights
                                        </div>
                                    </a>
                                </div>

                                <div className="flex flex-row">
                                    <a href="/profile"
                                       className="text-gray-700 dark:text-gray-300 group flex rounded-sm items-center w-full px-2 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-150">
                                        <div className="p-1">
                                            <svg width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-gray-700 dark:stroke-gray-300"><path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </div>
                                        <div className="">
                                            Profile
                                        </div>
                                    </a>
                                </div>


                                <div className="border-t border-gray-200 dark:border-gray-700 mt-1 pt-1">
                                    <div className="flex flex-row">
                                        <button
                                            onClick={toggleDarkMode}
                                            className="text-gray-700 dark:text-gray-300 group flex rounded-sm items-center w-full px-2 py-1.5 text-xs hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-150"
                                        >
                                            <div className="p-0.5">
                                                <FontAwesomeIcon 
                                                    icon={isDarkMode ? faSun : faMoon}
                                                    className="w-3.5 h-3.5"
                                                />
                                            </div>
                                            <div className="ml-2">
                                                {isDarkMode ? 'Light Mode' : 'Dark Mode'}
                                            </div>
                                        </button>
                                    </div>

                                    <div className="flex flex-row">
                                        <button
                                            onClick={signOutUser}
                                            className="text-gray-700 dark:text-gray-300 group flex rounded-sm items-center w-full px-1 py-2 text-sm bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-150">
                                            <div className="p-1">
                                                <svg width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-gray-700 dark:stroke-gray-300">
                                                    <path d="M12 12H19M19 12L16 15M19 12L16 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M19 6V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </div>
                                            <div className="ml-1">
                                                Logout
                                            </div>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                <div className="flex flex-row">
                    <button
                        onClick={toggleDarkMode}
                        className="text-gray-700 dark:text-gray-300 group flex rounded-sm items-center w-full px-2 py-1.5 text-xs hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-150 mr-4"
                    >
                        <div className="p-0.5">
                            <FontAwesomeIcon
                                icon={isDarkMode ? faSun : faMoon}
                                className="w-3.5 h-3.5"
                            />
                        </div>
                    </button>
                    <button
                        onClick={() => window.location.href='/login'}
                        className="justify-center w-full px-4 py-2 text-sm font-medium text-nav-bar-text-color border border-nav-bar-text-color rounded-lg bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        Login
                    </button>
                </div>

                </>
            )
            }
        </div>
    );
}

export default UserMenu;