
export const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/; 
    return regex.test(dateString);
};

export const formatDate = (dateString) => {
    if (!isValidDate(dateString)) {
        return 'Invalid Date';
    }
    const date = new Date(dateString);
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const formatDateObj = (date) => {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};


export const getRelativeDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);

    if (date.getTime() === today.getTime()) {
        return "Today";
    } else if (date.getTime() === yesterday.getTime()) {
        return "Yesterday";
    } else {
        return null;
    }
};

export function parseISODate(dateString) {
    const isUTC = dateString.endsWith('Z');

    // Remove the 'Z' if it exists
    dateString = isUTC ? dateString.slice(0, -1) : dateString;

    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);

    let hour = 0, minute = 0, second = 0, offsetHours = 0, offsetMinutes = 0;

    if (timePart) {
        const [time, offset] = timePart.split(/[-+]/);
        [hour, minute, second] = time.split(':').map(Number);

        if (offset && !isUTC) {
            [offsetHours, offsetMinutes] = offset.split(':').map(Number);
            const sign = timePart.includes('+') ? 1 : -1;
            offsetHours *= sign;
            offsetMinutes *= sign;
        }
    }

    if (isUTC) {
        return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
    } else {
        const date = new Date(year, month - 1, day, hour, minute, second);
        return new Date(date.getTime() - (offsetHours * 60 + offsetMinutes) * 60000);
    }
}