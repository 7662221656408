
export function formatCurrency(value, digits, symbol) {
    if (value >= 0) {
        return symbol + formatNumber(value, digits);
    } else {
        return `-${symbol}${formatNumber(Math.abs(value), digits)}`;
    }
}

export function formatNumber(value, digits) {
    if (isNaN(value)) {
        return "";
    }
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: digits,
        minimumFractionDigits: digits
    }).format(value);
}

export function arrayToStringWithCommasAndLengthLimitIntelligent(arr) {
    if (!Array.isArray(arr)) {
        return arr;
    }

    if (!arr.every(item => typeof item === 'string')) {
        throw new Error("Array contains non-string elements");
    }

    if (arr.length === 0) {
        return "";
    }

    if (arr.length === 1) {
        return arrayToStringWithCommas(arr);
    }

    if (arr.length === 2) {
        return arrayToStringWithCommas(arr);
    }

    return arrayToStringWithCommas(arr[0]);
}

export function arrayToStringWithCommasAndLengthLimit(arr, maxLength) {
    if (!Array.isArray(arr)) {
        return arr;
    }

    if (!arr.every(item => typeof item === 'string')) {
        throw new Error("Array contains non-string elements");
    }

    if (arr.length === 0) {
        return "";
    }

    const sanitizedArray = arr.map(item => sanitizeString(item));
    let result = "";

    for (const item of sanitizedArray) {
        if (result.length + item.length + (result.length > 0 ? 2 : 0) > maxLength) {
            break;
        }
        result += (result.length > 0 ? ", " : "") + item;
    }

    return result;
}

export function arrayToStringWithCommas(arr) {
    if (!Array.isArray(arr)) {
        return arr
    }

    if (!arr.every(item => typeof item === 'string')) {
        throw new Error("Array contains non-string elements");
    }

    if (arr.length === 0) {
        return "";
    }

    const sanitizedArray = arr.map(item => sanitizeString(item));

    return sanitizedArray.join(", ");
}

function sanitizeString(str) {
    return str.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export function formatCurrencyCompact(value) {
    if (typeof value !== 'number') return '';

    const absValue = Math.abs(value);
    const sign = value < 0 ? '-' : '';

    if (absValue >= 1e9) {
        return `${sign}$${formatNumber(absValue / 1e9, 1)}B`;
    } else if (absValue >= 1e6) {
        return `${sign}$${formatNumber(absValue / 1e6, 1)}M`;
    } else if (absValue >= 1e3) {
        return `${sign}$${formatNumber(absValue / 1e3, 0)}K`;
    } else {
        return formatCurrency(value, 0, "$");
    }
}