import React, {useEffect, useState} from 'react';
import { useAuth } from "../../firebase/AuthContext";
import axiosInstance from '../../api';
import { API_URL } from "../../config";
import NavBarSmall from "../NavBarSmall";
import Footer from "../Footer";
import Spinner from "../Spinner/Spinner";
import {useNavigate} from "react-router-dom";
import UserSubscriptionDetailsSmall from "../UserSubscriptionDetails/UserSubscriptionDetailsSmall";

const CancelSubscription = () => {
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const navigate = useNavigate();

    const [reasons, setReasons] = useState({
        tooExpensive: false,
        notEnoughValue: false,
        switchingToCompetitor: false,
        technicalIssues: false,
        other: false
    });
    const [message, setMessage] = useState('');

    const handleReasonChange = (reason) => {
        setReasons({ ...reasons, [reason]: !reasons[reason] });
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        if (currentUser) {
            const headers = { Authorization: `Bearer ${currentUser.token}` };
            setIsLoading(true);
            axiosInstance.get(`${API_URL}/api/users/subscription/info`, { headers })
                .then(response => {
                    console.log(response.data);
                    setSubscriptionData(response.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    setError('Failed to fetch subscription data');
                    setIsLoading(false);
                });
        }
    }, [currentUser]);

    const handleCancelSubscription = () => {
        if (currentUser) {
            setIsLoading(true);
            const headers = { Authorization: `Bearer ${currentUser.token}` };
            const payload = { reasons, message };

            axiosInstance.post(`${API_URL}/checkout/cancel-subscription`, payload, { headers })
                .then(response => {
                    console.log(response.data);
                    navigate('/portfolio')
                })
                .catch(error => {
                    console.error('Error cancelling subscription:', error);
                    setError('Failed to cancel subscription. Please try again.');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <div className="bg-filing-menu-background dark:bg-gray-900 bg-cover bg-center min-h-screen flex flex-col justify-between">
            <NavBarSmall />
            <div className="md:w-1/2 w-4/5 mx-auto my-10">
                <div className="bg-white dark:bg-gray-800 px-8 py-6 rounded-lg shadow-lg text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-700 space-y-4">
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">Cancel Subscription</h2>

                    {isLoading ? (
                        <div className="flex justify-center items-center h-96">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <UserSubscriptionDetailsSmall subscriptionData={subscriptionData} />
                            <div className={""}>
                                <div className="space-y-2">
                                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Please tell us why you're cancelling (optional):</p>
                                    {Object.entries(reasons).map(([key, value]) => (
                                        <div key={key} className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id={key}
                                                checked={value}
                                                onChange={() => handleReasonChange(key)}
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor={key} className="ml-2 block text-sm text-gray-900 dark:text-gray-200">
                                                {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-4 ">
                                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Additional feedback (optional):
                                    </label>
                                    <textarea
                                        id="message"
                                        rows="3"
                                        className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                                        placeholder="Please share any additional feedback..."
                                        value={message}
                                        onChange={handleMessageChange}
                                    ></textarea>
                                </div>

                                {error && (
                                    <div className="text-red-600 text-sm mt-2">
                                        {error}
                                    </div>
                                )}
                            </div>

                            <button
                                onClick={handleCancelSubscription}
                                className="mt-6 w-full bg-red-600 dark:bg-red-500 hover:bg-red-700 dark:hover:bg-red-600 text-white font-bold py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-sm transition duration-200 ease-in-out"
                            >
                                Confirm Cancel Subscription
                            </button>

                        </>

                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CancelSubscription;