import React from "react";
import { Link } from 'react-router-dom';
import {formatDateObj, getRelativeDate, parseISODate} from '../../dates';
import { themeColors } from '../../theme/colors';

function shortenName(name) {
    const maxLength = 9;
    if (name.length > maxLength) {
        return name.substring(0, maxLength) + '...';
    }
    return name;
}
function FilingCardWithCompany({ filing, isLastItem = false, numItems = 10 }) {

    let cssClasses = `${isLastItem ? "border-b dark:border-gray-600" : ""} ${filing.read ? "text-filing-is-read dark:text-gray-400" : `${themeColors.text.primary}`}`;

    const relativeDate = getRelativeDate(new Date(filing.updated));
    let dateLabelClass = "text-vsm mt-1 py-0 px-2 rounded-md font-semibold";
    if (relativeDate) {
        dateLabelClass += ` ${relativeDate === "Today" ? "bg-green-600 dark:bg-green-700 text-white" : "bg-amber-400 dark:bg-amber-600 text-gray-900 dark:text-gray-100"}`;
    }

    return (
        <div className={`${themeColors.background.secondary} shadow border-t ${themeColors.border.default} mb-0 ${cssClasses} py-0 m-0 transition-colors duration-200`}>
                <div className="flex flex-row justify-between items-center w-full">
                    <Link to={`${filing.url}`} className="rounded-sm w-full flex">
                        <div className={`flex items-center gap-2 text-xs py-2 md:py-1 pl-4 justify-between w-full ${themeColors.background.hover}`}>
                            <Link to={`/company/${filing.company.ticker ? filing.company.ticker[0] : filing.company.cik}`}>
                                <div className={`font-roboto flex flex-col items-start justify-start mr-2 whitespace-nowrap ${themeColors.background.hover} md:w-56 sm:w-16`}>
                                    {/* Width set to w-58 for the company name on larger screens */}
                                    <div className="hidden md:block w-56 truncate">
                                        {filing.company.name}
                                    </div>
                                    {/* Width set to w-16 for the ticker on smaller screens */}
                                    <div className="w-12 md:hidden">
                                        {filing.company.ticker ? filing.company.ticker[0] :
                                            shortenName(filing.company.name)}
                                    </div>
                                </div>
                            </Link>

                            <span className={`font-roboto font-bold w-16 overflow-hidden flex-shrink-0 ${themeColors.text.primary}`}>
                              {filing.filingType}
                            </span>
                            <span className={`w-2 ${themeColors.text.muted}`}>|</span>
                            {/*  */}
                            <span className={`font-roboto overflow-hidden whitespace-normal flex-grow-0 flex-shrink w-72 mr-4 ${themeColors.text.primary}`}>
                              {filing.title}
                            </span>
                            <div className="font-roboto flex flex-col items-end justify-end w-32 mr-2">
                              <span className={`text-xs ${themeColors.text.secondary}`}>
                                {formatDateObj(parseISODate(filing.updated))}
                              </span>
                                {relativeDate && (
                                    <span className={dateLabelClass}>{relativeDate}</span>
                                )}
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="icon"></div>
            </div>
    );
}

export default FilingCardWithCompany;
