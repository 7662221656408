export const themeColors = {
  background: {
    primary: 'bg-white dark:bg-gray-900',
    secondary: 'bg-gray-50 dark:bg-gray-900',
    card: 'bg-white dark:bg-gray-800',
    hover: 'hover:bg-slate-200 dark:hover:bg-gray-800',
  },
  text: {
    primary: 'text-gray-900 dark:text-gray-100',
    secondary: 'text-gray-600 dark:text-gray-300',
    muted: 'text-gray-500 dark:text-gray-400',
  },
  border: {
    default: 'border-gray-200 dark:border-gray-700',
    hover: 'hover:border-gray-300 dark:hover:border-gray-600',
  }
};