// Landing.js
import React, {useEffect} from 'react';
import SearchBar from './SearchBar/SearchBar';
import NavBar from "./NavBar";
import Footer from "./Footer";
import LandingBackground from "./LandingProductBackground";
import SubscriptionTier from './SubscriptionTier';
import { subscriptionPlans } from '../subscriptions/subscriptionPlans';


function Landing() {

    useEffect(() => {
        document.title = "Subscribe | Modeledge";
    }, []);


    return (
        <>
            <NavBar />
            <LandingBackground className="flex-grow">
                <div className="container mx-auto px-4 py-4">
                    <div className="mt-4"></div>
                    <div className="bg-nav-bar-theme border-2 border-white pb-14 pt-24 rounded-xl">
                        <div className="text-center">
                            <div className="md:block text-3xl md:text-4xl text-white mb-8 md:mx-0 mx-2"><strong>Modeledge</strong>: Unlock the <strong>Data</strong> and <strong>Insights</strong> for Smarter Investing</div>
                        </div>
                        <div>
                            <div className="mt-8 md:mx-0 mx-2">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 mb-16 flex flex-col md:flex-row justify-center items-stretch space-y-8 md:space-y-0 md:space-x-8">
                        <SubscriptionTier tier={subscriptionPlans.basic} />
                        <SubscriptionTier tier={subscriptionPlans.pro} />
                    </div>

                </div>
            </LandingBackground>
        <Footer />
        </>
    )
}

export default Landing;