import React from 'react';
import NavBarSmall from "./NavBarSmall";
import FooterSmall from "./FooterSmall";
import {useParams} from "react-router-dom";
import RecentFilingsIndustryGroupByTicker from "./RecentFilingsIndustryByTicker";

function RecentFilingsFeedIndustryByTicker() {
    const { ticker } = useParams();

    console.log("n=" + ticker)

    return (
        <div className="bg-gray-50 dark:bg-gray-900 bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="flex flex-col bg-gray-50 dark:bg-gray-900 min-h-screen">
                <div className="text-center bg-cover p-4">
                    <div className="w-full bg-white dark:bg-gray-800 pb-8 shadow-sm rounded-lg">
                        <div className="md:w-3/5 w-11/12 mx-auto pt-4">
                            <RecentFilingsIndustryGroupByTicker ticker={ticker} />
                        </div>
                    </div>
                </div>
            </div>
            <FooterSmall/>
        </div>
    );
}

export default RecentFilingsFeedIndustryByTicker;