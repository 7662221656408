import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import axiosInstance from '../../api';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {API_URL} from "../../config";
import FilingCardCompanySearch from "../FilingCards/FilingCardCompanySearch";
import TextSearchInput from "../TextSearchInput/TableSearchInput";
import debounce from 'lodash/debounce'; // Make sure to install lodash if not already installed

Modal.setAppElement('#root')

function TableSearchWithModal({ ticker }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [tables, setTables] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const searchBarRef = useRef(null);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpen(false);
        setTables([]);
    }, []);

    const handleTableClick = useCallback((id) => {
        navigate(`/tables/${ticker}/${id}`);
        closeModal();
    }, [navigate, ticker, closeModal]);

    const customStyles = useMemo(() => ({
        // ... (keep the existing styles)
    }), []);

    const searchFunction = useCallback((term) => {
        if (term) {
            axiosInstance
                .get(`${API_URL}/api/tables/search/${ticker}?q=${term}`)
                .then((response) => {
                    const tables = response.data;
                    setTables(tables);
                    if (tables != null && tables.length > 0) {
                        openModal();
                    } else {
                        closeModal();
                    }
                })
                .catch((error) => {
                    console.error("Error fetching suggestions:", error);
                    setTables([]);
                    closeModal();
                });
        } else {
            setTables([]);
            closeModal();
        }
    }, [ticker, openModal, closeModal]);

    const debouncedSearch = useMemo(
        () => debounce(searchFunction, 300),
        [searchFunction]
    );

    useEffect(() => {
        debouncedSearch(searchTerm);

        return () => {
            debouncedSearch.cancel();
        };
    }, [searchTerm, debouncedSearch]);

    const handleSearchChange = useCallback((newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    }, []);

    return (
        <div className="flex items-center bg-gray-100 rounded p-2 h-10 relative w-full max-w-4xl mx-auto">
            <TextSearchInput
                query={searchTerm}
                setQuery={handleSearchChange}
                handleSearch={() => {}}
                searchInputRef={searchBarRef}
                placeholder="Table search by keyword"
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Company Table"
                shouldFocusAfterRender={false}
                shouldReturnFocusAfterClose={false}
            >
                {tables.map((table) => (
                    <div
                        key={table.id}
                        onClick={() => handleTableClick(table.id)}
                        className="flex flex-col border border-gray-300 rounded px-0 cursor-pointer text-black w-full  bg-gray-50"
                    >
                        <FilingCardCompanySearch filing={table.filing} exhibit={table.exhibit} />
                        <h3 className="m-0 text-black font-bold pl-8 py-4">{table.table_name_llm}</h3>
                        <div className="flex justify-center" dangerouslySetInnerHTML={{ __html: table.html }} />
                    </div>
                ))}
            </Modal>
        </div>
    );
}

export default TableSearchWithModal;