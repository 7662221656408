import React from "react";
import Arrow from "../Arrow/Arrow2";
import CompanyButton from "../CompanyButton/CompanyButton";
import Logo from "../Logo";
import TranscriptMenuSecondaryData from "./TranscriptMenuSecondaryData";

const TranscriptMenu = ({
                           ticker,
                           type,
                            transcriptDetails,
                           menuVisible,
                           toggleMenu,
                       }) => {

    if (!transcriptDetails) {
        return <div className={`relative transition-transform duration-100 z-5 overflow-y-auto h-screen ${menuVisible ? '' : 'translate-x-[-100%+40px]'}`}>
            <Arrow onClick={toggleMenu} />
            <div className="font-sans bg-filing-menu-background overflow-y-auto h-full w-80">
                <div className="flex justify-center no-underline text-white">
                    <h1 className="font-nunito text-black mr-6 ml-2 my-4 mb-5 pt-1 text-3xl font-semibold font-sans">
                        <Logo />
                    </h1>
                </div>
                <div className={"rounded-sm mx-3 mb-2"}>
                    <CompanyButton company={""} ticker={""} />
                    <TranscriptMenuSecondaryData />
                </div>
            </div>
        </div>
    }

    return (
        <div className={`relative transition-transform duration-100 z-5 overflow-y-auto h-screen ${menuVisible ? '' : 'translate-x-[-100%+40px]'}`}>
            <Arrow onClick={toggleMenu} />
            <div className="font-sans bg-filing-menu-background overflow-y-auto h-full w-80">
                <div className="flex justify-center no-underline text-white">
                    <h1 className="font-nunito text-black mr-6 ml-2 my-4 mb-5 pt-1 text-3xl font-semibold font-sans">
                        <Logo />
                    </h1>
                </div>
                <div className={"rounded-sm mx-3 mb-2"}>
                    <CompanyButton company={transcriptDetails.company} ticker={ticker} />
                    <TranscriptMenuSecondaryData transcript_details={transcriptDetails} />
                </div>
            </div>
        </div>
    );
};

export default TranscriptMenu;