import React, { useEffect, useState } from 'react';
import axiosInstance from "../../api";
import { API_URL } from "../../config";
import Spinner from "../Spinner/Spinner"
import { useNavigate } from 'react-router-dom';


const UserSubscriptionDetails = ({ currentUser }) => {
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            const headers = { Authorization: `Bearer ${currentUser.token}` };
            setIsLoading(true);
            axiosInstance.get(`${API_URL}/api/users/subscription/info`, { headers })
                .then(response => {
                    console.log(response.data);
                    setSubscriptionData(response.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    setError('Failed to fetch subscription data');
                    setIsLoading(false);
                });
        }
    }, [currentUser]);

    const handleCancelSubscription = () => {
        navigate('/profile/actions/unsubscribe');
    };

    if (isLoading) {
        return (
            <div className="bg-white dark:bg-gray-800 px-8 py-6 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm mt-6">
                <div className="w-full flex justify-center items-center">
                    <Spinner />
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-600 dark:text-red-400">Error: {error}</div>;
    }

    if (!subscriptionData || !Array.isArray(subscriptionData) || subscriptionData.length === 0) {
        return (
            <div className="bg-white dark:bg-gray-800 px-8 py-6 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm mt-6">
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">No subscription</h3>
                <button
                    onClick={() => navigate('/subscriptions')}
                    className="mt-2 bg-blue-600 dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
                >
                   View Subscription options
                </button>
            </div>
        );
    }

    return (
        <div className="bg-white dark:bg-gray-800 px-8 py-6 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm mt-6">
            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">Subscription Details</h3>

            {subscriptionData.map((subscription, index) => (
                <div key={index} className="mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Product</p>
                            <p className="mt-1 text-md text-gray-900 dark:text-gray-100">{subscription.product_name}</p>
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Status</p>
                            <p className="mt-1 text-md text-gray-900 dark:text-gray-100">{subscription.active ? 'Active' : 'Inactive'}</p>
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Amount</p>
                            <p className="mt-1 text-md text-gray-900 dark:text-gray-100">{`$${subscription.amount / 100}`}</p>
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Current Period</p>
                            <p className="mt-1 text-md text-gray-900 dark:text-gray-100">
                                {`${new Date(subscription.current_period_start).toLocaleDateString()} - ${new Date(subscription.current_period_end).toLocaleDateString()}`}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Auto-renew</p>
                            <p className="mt-1 text-md text-gray-900 dark:text-gray-100">{subscription.cancel_at_period_end ? 'No' : 'Yes'}</p>
                        </div>
                    </div>
                </div>
            ))}

            <button
                onClick={handleCancelSubscription}
                className="mt-6 bg-red-600 dark:bg-red-500 hover:bg-red-700 dark:hover:bg-red-600 text-white font-bold py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
            >
                Cancel Subscription
            </button>
        </div>
    );
};

export default UserSubscriptionDetails;