import React from 'react';
import DownArrowIcon from './down-arrow.svg';

function LoadMoreFilingCards({ title = {}, numItems = 10, onLoadMore }) {
    let cardClasses = `bg-gray-100 dark:bg-gray-700 border border-b-2 border-t-0 border-gray-300 dark:border-gray-600 hover:bg-gray-200 dark:hover:bg-gray-600 px-4 py-1`;

    return (
        <div className={cardClasses} onClick={onLoadMore}>

            <div className="text-sm hover:text-blue-500">
                <div className="flex items-center m-1">
                    <div className="flex items-center justify-center mx-auto">
                        <div className="flex items-center justify-center mx-auto">
                            <img src={DownArrowIcon} alt="Load More" className="w-2 h-2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadMoreFilingCards;
